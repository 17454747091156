
interface State {
  title: string | undefined
  description: string | undefined
  keywords: string | undefined
  h1: {
    title: string | undefined
    subtitle: string | undefined
  }
}

export const useSeoStore = defineStore('seo', {
  state: (): State => {
    return <State>{
      title: undefined,
      description: undefined,
      keywords: undefined,
      h1: {
        title: undefined,
        subtitle: undefined
      },
    }
  }
})
