export const options = {
  "images": false,
  "videos": false,
  "audios": false,
  "iframes": false,
  "native": false,
  "directiveOnly": true,
  "defaultImage": false,
  "loadingClass": "isLoading",
  "loadedClass": "isLoaded",
  "appendClass": "lazyLoad",
  "observerConfig": {}
}