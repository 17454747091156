import { useAuthState } from '#imports'

export default defineNuxtPlugin(() => {
	const config = useRuntimeConfig()
	const { token} = useAuthState()

	const $api = $fetch.create({
		baseURL: config.public.baseURL,
		onRequest({ request, options, error }) {
			if (token.value) {
				options.headers = options.headers as Record<string, string> || {} as Record<string, string>
				options.headers.Authorization = token.value
			}
		},
		onResponseError({ response }) {
			if (response.status === 401) {
				return navigateTo('/auth') as Promise<void>
			}
		}
	})

	return {
		provide: {
			api: $api
		}
	}
})