export default defineAppConfig({
	ui: {
		primary: 'eden',
		gray: 'cool',
		button: {
			base: 'btn disabled:pointer-events-none disabled:cursor-default disabled:opacity-80',
			font: 'font-normal',
			color: {
				gray: {
					outline: 'bg-white dark:bg-gray-900 text-gray-900 dark:text-white ring-1 ring-inset ring-gray-300 dark:ring-gray-700 focus:ring-2 focus:ring-primary-500 dark:focus:ring-primary-400'
				}
			},
			variant: {
				outline: 'transition-colors duration-300 ring-{color}-500 hover:ring-{color}-500',
				link: 'underline underline-offset-4 decoration-transparent transition-colors duration-500 cursor-pointer hover:decoration-current focus:decoration-current',
			}
		},
		input: {
			base: 'focus:ring-opacity-80'
		},
		select: {
			base: 'cursor-pointer',
		},
		selectMenu: {
			select: 'cursor-pointer',
			option: {
				base: 'cursor-pointer',
				disabled: '!cursor-default'
			},
			transition: {
				enterActiveClass: 'transition duration-100 ease-out',
				enterFromClass: 'transform scale-95 opacity-0',
				enterToClass: 'transform scale-100 opacity-100',
				leaveActiveClass: 'transition duration-75 ease-in',
				leaveFromClass: 'transform scale-100 opacity-100',
				leaveToClass: 'transform scale-95 opacity-0',
			},
		},
		modal: {
			wrapper: 'relative z-[100]',
		},
		popover: {
			ring: 'focus:ring-2 focus:ring-teal-800',
			transition: {
				enterActiveClass: 'transition duration-100 ease-out',
				enterFromClass: 'transform scale-95 opacity-0',
				enterToClass: 'transform scale-100 opacity-100',
				leaveActiveClass: 'transition duration-75 ease-in',
				leaveFromClass: 'transform scale-100 opacity-100',
				leaveToClass: 'transform scale-95 opacity-0',
			},
		},
		slideover: {
			wrapper: 'bottom-0',
			translate: {
				base: 'translate-x-0 translate-y-0',
				bottom: 'translate-y-full rtl:-translate-y-full translate-x-0 rtl:-translate-x-0',
			},
		},
		notifications: {
			wrapper: 'justify-center group pointer-events-none',
			width: 'w-full sm:w-full',
			position: 'top-0 bottom-0 left-0 right-0 m-auto',
			container: 'absolute top-0 bottom-0 left-0 right-0 w-full before:absolute before:inset-0 before:bg-black/30 before:z-0 flex justify-center items-center [&>div]:z-50 [&>div]:w-full [&>div]:sm:w-96',
		},
		notification: {
			default: {
				closeButton: {
					size: 'xl'
				}
			},
			padding: 'px-6 py-5',
			title: 'text-base md:text-lg font-semibold text-gray-900 dark:text-white !leading-tight',
			description: '!mt-2 text-sm md:text-base leading-4 text-gray-500 dark:text-gray-400',
			actions: 'absolute top-[10px] right-[14px]',
			icon: {
				base: 'flex-shrink-0 !w-7 !h-7',
				color: 'text-{color}-500 dark:text-{color}-400',
			},
			progress: {
				base: 'hidden absolute bottom-0 end-0 start-0 h-1',
				background: 'bg-{color}-500/80 dark:bg-{color}-400',
			},
			transition: {
				enterActiveClass: 'transform ease-out duration-300 transition',
				enterFromClass: 'translate-y-2 opacity-0 sm:translate-y-2 sm:translate-x-0',
				enterToClass: 'translate-y-0 opacity-100 sm:translate-y-0',
				leaveActiveClass: 'transition ease-in duration-100',
				leaveFromClass: 'opacity-100',
				leaveToClass: 'opacity-0',
			},
		}
	}
})