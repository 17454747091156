export default defineNuxtPlugin((nuxtApp) => {
	nuxtApp.vueApp.directive('focus', {
		mounted (el) {
			let element = el
			if (el.tagName.toLowerCase() !== 'input') {
				element = el.querySelector('input')
			}
			if (element) {
				element.focus()
			}
		},
		getSSRProps () {
			return {}
		}
	})
})