import * as Sentry from '@sentry/vue'

function getSentryIntegrations(t: any) {
  if (!process.client) {
    return []
  }

  return [
    Sentry.browserTracingIntegration(),
    Sentry.browserProfilingIntegration(),
    Sentry.replayIntegration({
      maskAllText: false,
      maskAllInputs: false
    }),
    Sentry.feedbackIntegration({
      autoInject: false,
      colorScheme: 'light',
      showBranding: false,
      showName: true,
      showEmail: true,
      themeLight: {

      },
      themeDark: {
        background: 'rgb(31, 41, 55)',
        backgroundHover: 'rgba(55, 65, 81, 1.0)',
        border: '1.5px solid rgba(55, 65, 81, 1)',
        borderRadius: '6px',
        boxShadow: 'rgb(255, 255, 255) 0px 0px 0px 0px',
        submitBackground: 'rgb(56, 189, 248)',
        submitBackgroundHover: 'rgb(14, 165, 233)',
        submitForeground: 'rgb(17, 24, 39)',
        submitForegroundHover: 'rgb(17, 24, 39)',
        submitBorder: 'transparent',
        cancelBackground: 'transparent',
        cancelBackgroundHover: 'rgb(8, 47, 73)',
        cancelBorder: '1px solid rgb(56, 189, 248)',
        cancelForeground: 'rgb(56, 189, 248)',
        cancelForegroundHover: 'rgb(56, 189, 248)',
        inputBorder: '1px solid rgb(107, 114, 128)',
        inputBackground: 'transparent',
        inputOutlineFocus: 'rgb(56, 189, 248)',
        formBorderRadius: '6px',
        formContentBorderRadius: '6px',
        fontFamily: 'Montserrat',
        success: 'rgb(0, 220, 130)',
        error: 'rgb(239, 68, 68)',
      },
      formTitle: t('sentry.report.title'),
      nameLabel: t('sentry.report.field.name'),
      namePlaceholder: t('sentry.report.placeholder.name'),
      emailLabel: t('sentry.report.field.email'),
      emailPlaceholder: t('sentry.report.placeholder.email'),
      messageLabel: t('sentry.report.field.message'),
      messagePlaceholder: t('sentry.report.placeholder.message'),
      isRequiredLabel: t('sentry.report.message.required'),
      buttonLabel: t('sentry.report.button.show'),
      submitButtonLabel: t('sentry.report.button.submit'),
      cancelButtonLabel: t('sentry.report.button.cancel'),
      successMessageText: t('sentry.report.message.success')
    }),
    Sentry.metrics.metricsAggregatorIntegration(),
  ]
}

export default defineNuxtPlugin({
  name: 'sentry',
  parallel: true,
  async setup(nuxtApp) {
    const vueApp = nuxtApp.vueApp
    const i18n = nuxtApp.$i18n as any

    const config = useRuntimeConfig()

    Sentry.init({
      app: vueApp,
      dsn: config.public.SENTRY_DSN_PUBLIC ?? null,
      environment: config.public.SENTRY_ENVIRONMENT as string ?? 'dev',
      integrations: getSentryIntegrations(i18n.t),
      tracesSampleRate: config.public.SENTRY_TRACES_SAMPLE_RATE as number,
      profilesSampleRate: config.public.SENTRY_PROFILES_SAMPLE_RATE as number,
      replaysSessionSampleRate: config.public.SENTRY_REPLAY_SAMPLE_RATE as number,
      replaysOnErrorSampleRate: config.public.SENTRY_ERROR_REPLAY_SAMPLE_RATE as number,
      enabled: config.public.SENTRY_ENABLED.toLowerCase() === 'true' && process.env.NODE_ENV !== 'development',
    })
  }
})
