<script lang="ts" setup>
import type { NuxtError } from '#app'

const props = defineProps({
	error: Object as () => NuxtError
})

const { t } = useI18n({
	useScope: 'local'
})

const seoStore = useSeoStore()

const handleError = () => clearError({ redirect: '/' })

const getMessage = computed(() => {
	switch (props.error?.statusCode) {
		case 401:
			return t('message.401')
		case 404:
			return t('message.404')
		default:
			return props.error?.message
	}
})

seoStore.title = getMessage.value
</script>

<template>
	<div
		class="font-sans bg-white text-black grid min-h-[100svh] place-content-center overflow-hidden"
	>
		<div class="fixed left-0 right-0 spotlight z-10"></div>
		<div class="max-w-520px text-center z-20">
			<h1 class="text-8xl sm:text-10xl font-medium mb-8">
				{{ error?.statusCode ?? 500 }}
			</h1>
			<p class="text-xl px-8 sm:px-0 sm:text-4xl font-light mb-16 leading-tight">
				{{ getMessage }}
			</p>
			<div class="w-full flex items-center justify-center">
				<UButton
					variant="outline"
					size="lg"
					@click="handleError"
				>
					{{ t('button.back') }}
				</UButton>
			</div>
		</div>
	</div>
</template>

<i18n lang="json">
{
	"ru": {
		"button": {
			"back": "Перейти на главную"
		},
		"message" : {
			"401": "Вы не авторизованы",
			"404": "Страница не найдена",
			"500": "Произошла непредвиденная ошибка"
		}
	}
}
</i18n>
